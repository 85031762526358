/* 
[data-product-id="1513975939190"]
[data-product-id="1570176532598"]
[data-product-id="1570228600950"]
[data-product-id="1571607478390"]
[data-product-id="1571629826166"]
*/

/*
[data-product-id="1513975939190"] .grid,
[data-product-id="1570176532598"] .grid,
[data-product-id="1570228600950"] .grid,
[data-product-id="1571607478390"] .grid,
[data-product-id="1571629826166"] .grid { display: flex; justify-content: end; }

[data-product-id="1513975939190"] .medium-up--two-fifths,
[data-product-id="1570176532598"] .medium-up--two-fifths,
[data-product-id="1570228600950"] .medium-up--two-fifths,
[data-product-id="1571607478390"] .medium-up--two-fifths,
[data-product-id="1571629826166"] .medium-up--two-fifths { width: 40%; padding-left: 30px; }

[data-product-id="1513975939190"] .medium-up--three-fifths,
[data-product-id="1570176532598"] .medium-up--three-fifths,
[data-product-id="1570228600950"] .medium-up--three-fifths,
[data-product-id="1571607478390"] .medium-up--three-fifths,
[data-product-id="1571629826166"] .medium-up--three-fifths { width: 60%; padding-left: 30px; }



@media only screen and (min-width: 992px){
  
    [data-product-id="1513975939190"] .page-content,
    [data-product-id="1570176532598"] .page-content,
    [data-product-id="1570228600950"] .page-content,
    [data-product-id="1571607478390"] .page-content,
    [data-product-id="1571629826166"] .page-content { padding-top: 0;}

    [data-product-id="1513975939190"] .grid,
    [data-product-id="1570176532598"] .grid,
    [data-product-id="1570228600950"] .grid,
    [data-product-id="1571607478390"] .grid,
    [data-product-id="1571629826166"] .grid {display: flex;justify-content: end;}

    [data-product-id="1513975939190"] .medium-up--two-fifths,
    [data-product-id="1570176532598"] .medium-up--two-fifths,
    [data-product-id="1570228600950"] .medium-up--two-fifths,
    [data-product-id="1571607478390"] .medium-up--two-fifths,
    [data-product-id="1571629826166"] .medium-up--two-fifths { width: 25%; padding-left: 75px;}

    [data-product-id="1513975939190"] .medium-up--three-fifths,
    [data-product-id="1570176532598"] .medium-up--three-fifths,
    [data-product-id="1570228600950"] .medium-up--three-fifths,
    [data-product-id="1571607478390"] .medium-up--three-fifths,
    [data-product-id="1571629826166"] .medium-up--three-fifths {width: 35%; padding-left: 0;}

    [data-product-id="1513975939190"] .product-single__title,
    [data-product-id="1570176532598"] .product-single__title,
    [data-product-id="1570228600950"] .product-single__title,
    [data-product-id="1571607478390"] .product-single__title,
    [data-product-id="1571629826166"] .product-single__title { font-size: 20px;}

    [data-product-id="1513975939190"] span.product__price,
    [data-product-id="1570176532598"] span.product__price,
    [data-product-id="1570228600950"] span.product__price,
    [data-product-id="1571607478390"] span.product__price,
    [data-product-id="1571629826166"] span.product__price {font-size: 30px !important;}

}
*/ 

@media only screen and (min-width: 1200px){
    [data-product-id="1513975939190"] .grid,
    [data-product-id="1570176532598"] .grid,
    [data-product-id="1570228600950"] .grid,
    [data-product-id="1571607478390"] .grid,
    [data-product-id="1571629826166"] .grid { display: flex; justify-content: end; margin-left: 42%}
    
    [data-product-id="1513975939190"] .medium-up--two-fifths,
    [data-product-id="1570176532598"] .medium-up--two-fifths,
    [data-product-id="1570228600950"] .medium-up--two-fifths,
    [data-product-id="1571607478390"] .medium-up--two-fifths,
    [data-product-id="1571629826166"] .medium-up--two-fifths { width: 40%;}
    
    [data-product-id="1513975939190"] .medium-up--three-fifths,
    [data-product-id="1570176532598"] .medium-up--three-fifths,
    [data-product-id="1570228600950"] .medium-up--three-fifths,
    [data-product-id="1571607478390"] .medium-up--three-fifths,
    [data-product-id="1571629826166"] .medium-up--three-fifths { width: 60%;}

    [data-product-id="1513975939190"] .medium-up--three-fifths .product-single__meta,
    [data-product-id="1570176532598"] .medium-up--three-fifths .product-single__meta,
    [data-product-id="1570228600950"] .medium-up--three-fifths .product-single__meta,
    [data-product-id="1571607478390"] .medium-up--three-fifths .product-single__meta,
    [data-product-id="1571629826166"] .medium-up--three-fifths .product-single__meta{
        padding-left: 0!important;
    }
    
    .product-single__title{font-size: 30px;}
    
    [data-product-id="1513975939190"] .medium-up--three-fifths .product-single__title,
    [data-product-id="1570176532598"] .medium-up--three-fifths .product-single__title,
    [data-product-id="1570228600950"] .medium-up--three-fifths .product-single__title,
    [data-product-id="1571607478390"] .medium-up--three-fifths .product-single__title,
    [data-product-id="1571629826166"] .medium-up--three-fifths .product-single__title{
        font-size: 30px;
    }
    
    .product-block>.rte>ul {
        margin: 0 0 20px 20px!important;
    }
}



.parallax-container .parallax-image {
  left: unset!important;
  bottom: unset!important;
  width: unset!important;
  transform: unset!important;
  top: unset!important;
  position: static!important;
}

section#shopify-section-template--16233122955427__16809926711b5084a2>.index-section {
    margin:  0!important;
}

section#shopify-section-template--16233122955427__16809926711b5084a2>.index-section>.page-width{
    padding:  0!important;
}

.page-width--narrow {
    max-width: 1320px!important;
    margin: 0 auto!important;
}

button.afterpay-logo.brand-afterpay.compact-badge.type-badge.black-on-mint{cursor: default!important;}

p.customluve.afterpay-paragraph {
    font-size: 15px;
    line-height: 1.2;
    margin-top:  10px;
}

@media only screen and (min-width: 576px){
    p.customluve.afterpay-paragraph {
        font-size: 16px;
        line-height: 36px;
        display: flex;
        margin-top: 0;
    }
}

p.customluve.afterpay-paragraph .afterpay-logo-link,
p.customluve.afterpay-paragraph .afterpay-logo-link button{
    height: 36px!important;
    display: block!important;
    margin: 0;
    padding: 0!important;
    margin:  0!important;
    top:  0;
    line-height: 20px!important;
}

@media only screen and (min-width: 576px){
    p.customluve.afterpay-paragraph .afterpay-logo-link{
        padding-left: 10px!important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px){
    .product-single__meta{
        padding-left: 15px!important;
    }
}

@media only screen and (min-width: 280px) and (max-width: 767px){
    .product__photos{
        border: 20px solid white;
        box-shadow: 0 2px 3px 0 rgba(0,0,0,.25)!important;
        border-radius: 3px;
    }
}